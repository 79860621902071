import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import './header.css';

class Header extends Component {
    goHome = () => {
        this.props.history.push('/');
    }

    goExplain = () => {
        this.props.history.push('/explain');
    }

    goPricing = () => {
        this.props.history.push('/pricing');
    }

    goQuestionForm = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSesLrNi0vcn0ZwsOXcVmq29eihPd5c02E6TKjXpiBfkiob_DA/viewform?usp=sf_link')
    }

    render() {
        return (
            <div className="header_container">
                <div className="logo_field">
                    <h1 className="logo_field"><img className="logo" onClick={this.goHome} src="images/logo_cobot.png" alt="logo" /></h1>
                </div>
                <div className="button_field">
                    <button className={(this.props.location.pathname === '/explain') ? 'explain_page_info_button' : 'info_button'} onClick={this.goExplain}>서비스 소개</button>
                    <button className="question_button" onClick={this.goPricing}>가격정책</button>
                    <button className="question_button" onClick={this.goQuestionForm}>문의하기</button>
                </div>
            </div>
        )
    }
}

export default withRouter(Header);