import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import TagManager from 'react-gtm-module';

const tagManagerId = {
    gtm: 'GTM-KNWJ9DB'
}
TagManager.initialize(tagManagerId);

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root')
);
