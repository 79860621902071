import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './components/pages/home/home';
import Explain from './components/pages/explain/explain';
import Pricing from './components/pages/pricing/pricing';

import './reset.css';
import './App.css';

class App extends Component {
    render () {
        return (
            <div className="App">
                <Router>
                    <div className="header">
                        <Header />
                    </div>
                    <Switch>
                        <Route exact path="/" component={Home}></Route>
                        <Route path="/explain" component={Explain}></Route>
                        <Route path='/privacy' component={() => { 
                            window.location.href = 'https://sites.google.com/pocl.io/privacy'; 
                            return null;
                        }}/>
                        <Route path="/pricing" component={Pricing}></Route>
                    </Switch>
                    </Router>
                <Footer />
            </div>
        )
    }
}

export default App;
