import React, { Component } from 'react';

import { isDesktop }  from  'react-device-detect' ;
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import './home.css';

class Home extends Component {
    goServiceExplain = () => {
        this.props.history.push('/explain');
    }

    goQuestionForm = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSesLrNi0vcn0ZwsOXcVmq29eihPd5c02E6TKjXpiBfkiob_DA/viewform?usp=sf_link')
    }

    constructor(props) {
        super(props);
        this.state = {
            alertOpen: false,
            selectBoxActive: "",
            explainDialogActive: false
        }
    }
    
    getExplainDownloadDialog = () => {
        return (
            <Dialog onClose={ this.closeDialog }
                open={this.state.alertOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>해당 앱은 PC에서 사용가능합니다. 모바일에서 다운받으시겠습니까?
                    <Button className="explain_dialog_close_btn" onClick={this.closeDialog}>
                        취소
                    </Button>
                    <Button className="explain_dialog_confirm_btn" onClick={() => {this.closeDialog(); this.setState({explainDialogActive : true})}}>
                        확인
                    </Button>
                </DialogContent>
            </Dialog>
        )
    }
    
    closeDialog = () => {
        return this.setState({ alertOpen: false });
    }

    toggleSelectBox = () => {
        if (this.state.selectBoxActive === "" && this.state.explainDialogActive === false && !isDesktop) {
            this.setState({ alertOpen: true })
            return;
        }
        if (this.state.selectBoxActive === "") {
            this.setState({ selectBoxActive: "cobot_download_button_open"});
        } else {
            this.setState({ selectBoxActive: ""});
        }
    }

    render() {
        const { alertOpen } = this.state;
        return (
            <div className="home_container">
                <div className="top">
                    <div className="top_container">
                        <div className="top_title_field">
                            <h2 className="top_title">
                                영상 메신저로 빠르고 정확한
                            </h2>
                            <h2 className="top_title">질의 응답, 코봇아이</h2>
                        </div>
                        <div className="top_button_field">
                            <button
                                className="btn btn_primary btn_large start_button"
                                onClick={this.goServiceExplain}
                            >
                                시작하기
                            </button>
                        </div>
                    </div>
                    <div className="home_top_image_field">
                        <img src="images/bg_home_top.svg" alt="배경 이미지" />
                    </div>
                    <div className="mobile_image_filed">
                        <img
                            className="mobile_home_top_image"
                            src="/images/bg_home_top_img.svg"
                            alt="배경 이미지"
                        />
                    </div>
                </div>
                <div className="content_slider">
                    <div className="content_slider_container">
                        <HomeSlider></HomeSlider>
                    </div>
                </div>
                <div className="cobot_download_wrap">
                    <div className="cobot_download_container">
                        <p className="cobot_download_title">
                            지금 코봇아이를 시작해보세요!
                        </p>
                        <p className="cobot_download_sub_title">
                            영상으로 빠르고 정확하게 질문과 답변을 주고 받을 수
                            있습니다.
                        </p>
                        <p className="cobot_download_sub_title_mobile">
                            영상으로 빠르고 정확하게
                            <br /> 질문과 답변을 주고 받을 수 있습니다.
                        </p>
                        <p className="cobot_download_button_sub_label">
                            Windows, Mac 용 애플리케이션
                        </p>
                        <button
                            className={
                                "cobot_download_button " +
                                this.state.selectBoxActive
                            }
                            onClick={() => this.toggleSelectBox()}
                        >
                            <span className="cobot_download_button_label">
                                앱 다운로드 하기
                            </span>
                            <img
                                src="images/ic-arrow-down.svg"
                                className="cobot_download_button_icon"
                                alt="앱 다운로드"
                            />
                            <ul className="cobot_download_select_os_wrap">
                                <li className="cobot_download_select_os">
                                    <a
                                        href="ms-windows-store://pdp?productId=9NK2CV9W32ST"
                                        className="cobot_download_select_os_link"
                                    >
                                        <img
                                            src="images/ic_window.png"
                                            alt="Windows"
                                        />
                                        <span>Windows</span>
                                    </a>
                                </li>
                                <li className="cobot_download_select_os">
                                    <a
                                        href="https://update.cobot-i.com:5000/download/latest/osx"
                                        className="cobot_download_select_os_link"
                                    >
                                        <img
                                            src="images/ic_mac.png"
                                            alt="Mac"
                                        />
                                        <span>Mac</span>
                                    </a>
                                </li>
                            </ul>
                        </button>
                    </div>
                </div>
                <div className="cobot_question_wrap">
                    <div className="cobot_question_container">
                        <div>
                            <p className="cobot_question_title">
                                도움이 필요하신가요? <br /> 코봇아이 관련
                                문의사항을 남겨주세요.
                            </p>
                            <button
                                className="cobot_question_button"
                                onClick={this.goQuestionForm}
                            >
                                문의하기
                            </button>
                        </div>
                        <div className="cobot_question_image_field">
                            <img
                                className="image"
                                src="images/bg_home_third_img.svg"
                                alt="배경 이미지"
                            />
                        </div>
                    </div>
                </div>
                {alertOpen && this.getExplainDownloadDialog()}
            </div>
        );
    }
}

class HomeSlider extends Component {
    slideIndex = 1;
    prevSvg = "images/ic_arrow_left.svg";
    mainSvg = "images/home_slied_1.png";
    title = "영상으로 하는 생생한 소통";
    subTitleFront = "화면 녹화를 통해 어떤 내용이든";
    subTitleEnd = "전달이 가능해요.";
    goPrev = (index) => {
        index--;
        if (index === 0) {
            return;
        }
        switch(index) {
            case 1:
                this.prevSvg = "images/ic_arrow_left.svg";
                break;
            case 2:
                this.prevSvg = "images/ic_arrow_left_active.svg";
                break;
            default:
                break;
        }
        this.slideIndex = index;
        this.changeContent(this.slideIndex);
        this.setState({slideIndex: index});
    }

    goNext = (index) => {
        index++;
        switch(index) {
            case 2:
                this.prevSvg = "images/ic_arrow_left_active.svg";
                break;
            case 3:
                this.prevSvg = "images/ic_arrow_left_active.svg";
                break;
            case 4:
                this.prevSvg = "images/ic_arrow_left.svg";
                index = 1;
                break;
            default:
                break;
        }
        this.slideIndex = index;
        this.changeContent(this.slideIndex);
        this.setState({slideIndex: index});
    }

    changeContent = (index) => {
        switch(index) {
            case 1:
                this.title = "영상으로 하는 생생한 소통";
                this.subTitleFront = "화면 녹화를 통해 어떤 내용이든";
                this.subTitleEnd = "전달이 가능해요.";
                this.mainSvg = "images/home_slied_1.png";
                break;
            case 2:
                this.title = "언제든 궁금한 점 해결";
                this.subTitleFront = "내가 보낸 질문에 대하여";
                this.subTitleEnd = "전문가들의 답변을 바로 받을 수 있어요.";
                this.mainSvg = "images/home_slied_2.png";
                break;
            case 3:
                this.title = "다양한 기능으로 효율적 학습 관리";
                this.subTitleFront = "그룹 및 개인메시지, 공지기능 등을 통해";
                this.subTitleEnd = "효과적으로 학습할 수 있어요.";
                this.mainSvg = "images/home_slied_3.png";
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="content_slider_warp">
                <div className="content_image_field">
                    <img src={this.mainSvg} alt="메인 슬라이드 이미지" className="content_slider_image"/>
                </div>
                <div className="content_slider_controll_wrap">
                    <div className="content_slider_controll">
                        <div>
                            <h2 className="content_slider_title">{this.title}</h2>
                            <div className="content_slider_content">
                                <span>
                                    {this.subTitleFront}
                                </span>
                                <span>
                                    {this.subTitleEnd}
                                </span>
                            </div>
                        </div>
                        <div className="content_slider_button_wrap">
                            <button className="button_prev" onClick={() => this.goPrev(this.slideIndex)}>
                                <img src={this.prevSvg} alt="이전 페이지"/>
                            </button>
                            <p>{this.slideIndex}/3</p>
                            <button className="button_next" onClick={() => this.goNext(this.slideIndex)}>
                                <img src="images/ic_arrow_right.svg" alt="다음 페이지"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Home);