import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import './pricing.css';

class Pricing extends Component {

    openContactForm = () => {
        window.open('https://forms.gle/4cHCnRMYBeuNnc4X7');
    }

    render() {
        return (
            <section className="pricing_section">
                <h1 className="pricing_section_title">가격 플랜을 비교해보세요.</h1>
                <span>현재 온라인 결제가 지원되지 않습니다. 곧 업데이트 될 예정입니다.</span>
                <div class="card_section">
                    <div className="pricing_card">
                        <p className="price_number">무료</p>
                        <p className="term">처음 3개월간</p>
                        <div className="divider"></div>
                        <ul className="features_list">
                            <li>3인 이하 무료</li>
                        </ul>
                        <button className="btn_text" onClick={this.openContactForm}>문의하기</button>
                    </div>
                    <div className="pricing_card">
                        <p className="price_number">5,000 원</p>
                        <p className="term">1인당 1개월</p>
                        <div className="divider"></div>
                        <ul className="features_list">
                            <li>3 - 20 인 규모</li>
                        </ul>
                        <button className="btn_text" onClick={this.openContactForm}>문의하기</button>
                    </div>
                    <div className="pricing_card">
                        <p className="price_number">영업팀 문의</p>
                        <p className="term">대량 구매</p>
                        <div className="divider"></div>
                        <ul className="features_list">
                            <li>20 인 이상</li>
                        </ul>
                        <button className="btn_text" onClick={this.openContactForm}>문의하기</button>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(Pricing);