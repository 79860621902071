import React, { Component } from 'react';

import './footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="footer_container">
                <div className="footer_inner_container">
                    <div className="company_name_field">
                        <p>(주)에이럭스</p>
                    </div>
                    <div className="company_desc">
                        <dl className="company_number_field">
                            <dt className="company_number_label">사업자 등록번호 </dt>
                            <dd className="company_number">587 - 81 - 00224</dd>
                        </dl>
                        <dl className="address_field">
                            <dt className="company_address_label">주소 </dt>
                            <dd className="company_address">서울특별시 마포구 매봉산로 37 1001호</dd>
                        </dl>
                        <dl className="phone_field">
                            <dt className="company_email_label">문의 </dt>
                            <dd className="company_email">contact@pocl.io</dd>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;