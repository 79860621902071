import React, { Component } from 'react';

import { isDesktop }  from  'react-device-detect' ;
import { Tabs, Tab, Button, Dialog, DialogContent } from '@material-ui/core';
import './explain.css';

const activeTabStyle = {
    default_tab:{
        minWidth: 180,
        backgroundColor: '#FFFFFF',
        fontSize: 15,
        color: '#A2A2A2'
    },
    active_tab:{
        minWidth: 180,
        backgroundColor: '#FFFFFF',
        fontSize: 15,
        color: '#3459BE'
    }
};

class Explain extends Component {
    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    }

    getStyle = (isActive) => {
        return isActive ? activeTabStyle.active_tab : activeTabStyle.default_tab;
    }

    getStudentTabIcon = (isActive) => {
        if(!isActive){
            return <div className='tab_wrap'><img src='images/ic_student_normal.svg' alt="학습자"/><span>학습자</span></div>
        }
        return <div className='tab_wrap'><img src='images/ic_student_selcet.svg' alt="학습자"/><span>학습자</span></div>
    }

    getManagerTabIcon = (isActive) => {
        if(!isActive){
            return <div className='tab_wrap'><img src='images/ic_manager_normal.svg' alt="매니저"/><span>매니저</span></div>
        }
        return <div className='tab_wrap'><img src='images/ic_manager_selcet.svg' alt="매니저"/><span>매니저</span></div>
    }

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            alertOpen: false,
            videoOpen: false,
            videoUrl: "",
            selectBoxActive: "",
            explainDialogActive: false
        }
    }

    getExplainDownloadDialog = () => {
        return (
            <Dialog onClose={ this.closeDialog }
                open={this.state.alertOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>해당 앱은 PC에서 사용가능합니다. 모바일에서 다운받으시겠습니까?
                    <Button className="explain_dialog_close_btn" onClick={this.closeDialog}>
                        취소
                    </Button>
                    <Button className="explain_dialog_confirm_btn" onClick={() => {this.closeDialog(); this.setState({explainDialogActive : true})}}>
                        확인
                    </Button>
                </DialogContent>
            </Dialog>
        )
    }

    closeDialog = () => {
        return this.setState({ alertOpen: false });
    }

    closeVideoPopup = () => {
        return this.setState({ videoOpen: false });
    }

    goCobotTutor = () => {
        window.open('https://answer.cobot-i.com');
    }

    setVideoOpen = (videoName) => {
        let url = "";
        switch (videoName) {
            case "install":
                url = "https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/guide_videos%2Finstall_guide.mp4?alt=media&token=2c8ece3e-fc2a-49f6-b099-9581cf0c555c";
                break;
            case "question":
                url = "https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/guide_videos%2Fquestion_guide.mp4?alt=media&token=5471c179-ed53-4acf-8b97-9a594b415945";
                break;
            case "answer":
                url = "https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/guide_videos%2Fanswer_guide.mp4?alt=media&token=0effe754-cafd-4d12-9a9a-aee083c34a32";
                break;
            default:
                return this.setState({ videoOpen: false });
        }
        return this.setState({ videoOpen: true , videoUrl: url });
    }
    
    showVideoPopup = () => {
        return (
            <VideoPopup state={this.state} close={this.closeVideoPopup}></VideoPopup>
        )
    }
    
    toggleSelectBox = () => {
        if (this.state.selectBoxActive === "" && this.state.explainDialogActive === false && !isDesktop) {
            this.setState({ alertOpen: true })
            return;
        }
        if (this.state.selectBoxActive === "") {
            this.setState({ selectBoxActive: "cobot_download_button_open" });
        } else {
            this.setState({ selectBoxActive: "" });
        }
    }

    render() {
        const {alertOpen, videoOpen} = this.state;
        return (
            <div className="explain_container">
                <div className="explain_first_section">
                    <div>
                        <div className="explain_first_section_title">
                            <p>역할에 맞는 기능을 알아보세요.</p>
                        </div>
                        <div className="explain_first_section_mobile_title">
                            <p className="explain_first_section_mobile_title_firstline">
                                역할에 맞는 기능을 알아보세요.
                            </p>
                        </div>
                        <div className="tab_container">
                            <Tabs
                                centered
                                value={this.state.value}
                                TabIndicatorProps={{
                                    style: { height: "4px" },
                                }}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                            >
                                <Tab
                                    style={this.getStyle(
                                        this.state.value === 0
                                    )}
                                    icon={this.getStudentTabIcon(
                                        this.state.value === 0
                                    )}
                                />
                                <Tab
                                    style={this.getStyle(
                                        this.state.value === 1
                                    )}
                                    icon={this.getManagerTabIcon(
                                        this.state.value === 1
                                    )}
                                />
                            </Tabs>
                            <TabPanel value={this.state.value} index={0}>
                                <div className="tab_container">
                                    <div className="tab_content">
                                        <div className="video_wrap">
                                            <video
                                                autoPlay="autoPlay"
                                                loop
                                                className="tab_video"
                                            >
                                                <source
                                                    type="video/mp4"
                                                    src="https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/cobot_landing_videos%2Flanding-1.mp4?alt=media&token=b8f35b52-ed7e-445e-a533-60b44b2ca883"
                                                ></source>
                                            </video>
                                        </div>
                                        <div className="title_wrap">
                                            <p className="tab_title">
                                                어떠한 내용이든 빠르게 녹화
                                            </p>
                                            <p className="tab_sub_title">
                                                카메라, 화면 녹화로 내용을
                                                정확하게 전달할 수 있어요.
                                                <span>
                                                    작성할 내용에 대한 고민 없이
                                                    빠르고 간편해요.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab_content">
                                        <div className="video_wrap video_wrap_left_video">
                                            <video
                                                autoPlay="autoPlay"
                                                loop
                                                className="tab_video"
                                            >
                                                <source
                                                    type="video/mp4"
                                                    src="https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/cobot_landing_videos%2Flanding-2.mp4?alt=media&token=07920dbb-1d1b-4557-92a3-b2221bf131dc"
                                                ></source>
                                            </video>
                                        </div>
                                        <div className="title_wrap">
                                            <p className="tab_title">
                                                언제든지 정확한 소통
                                            </p>
                                            <p className="tab_sub_title">
                                                정확한 답변과 학습관련 메시지를
                                                받을 수 있어요.
                                                <span>
                                                    다양한 방법으로 소통하며
                                                    언제든지 질문을 해결합니다.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab_content">
                                        <div className="video_wrap">
                                            <video
                                                autoPlay="autoPlay"
                                                loop
                                                className="tab_video"
                                            >
                                                <source
                                                    type="video/mp4"
                                                    src="https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/cobot_landing_videos%2Flanding-3.mp4?alt=media&token=8a13c068-ec4d-47c0-84e5-886858cb6920"
                                                ></source>
                                            </video>
                                        </div>
                                        <div className="title_wrap">
                                            <p className="tab_title">
                                                효율적인 질문 관리
                                            </p>
                                            <p className="tab_sub_title">
                                                메시지 구분 기능을 통해 질문,
                                                답변을 모아 볼 수 있어요.
                                                <span>
                                                    다시 찾는 번거로움 없이 학습
                                                    효율이 높아집니다.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="cobot_download_wrap">
                                        <div className="cobot_download_container">
                                            <p className="cobot_download_title">
                                                지금 코봇아이를 시작해보세요!
                                            </p>
                                            <p className="cobot_download_sub_title">
                                                영상으로 빠르고 정확하게 질문과
                                                답변을 주고 받을 수 있습니다.
                                            </p>
                                            <p className="cobot_download_sub_title_mobile">
                                                영상으로 빠르고 정확하게
                                                <span>
                                                    질문과 답변을 주고 받을 수
                                                    있습니다.
                                                </span>
                                            </p>
                                            <p className="cobot_download_button_sub_label">
                                                Windows, Mac 용 애플리케이션
                                            </p>
                                            <button
                                                className={
                                                    "cobot_download_button " +
                                                    this.state.selectBoxActive
                                                }
                                                onClick={() =>
                                                    this.toggleSelectBox()
                                                }
                                            >
                                                <span className="cobot_download_button_label">
                                                    앱 다운로드 하기
                                                </span>
                                                <img
                                                    src="images/ic-arrow-down.svg"
                                                    className="cobot_download_button_icon"
                                                    alt="앱 다운로드"
                                                />
                                                <ul className="cobot_download_select_os_wrap">
                                                    <li className="cobot_download_select_os">
                                                        <a
                                                            href="ms-windows-store://pdp?productId=9NK2CV9W32ST"
                                                            className="cobot_download_select_os_link"
                                                        >
                                                            <img
                                                                src="images/ic_window.png"
                                                                alt="Windows"
                                                            />
                                                            <span>Windows</span>
                                                        </a>
                                                    </li>
                                                    <li className="cobot_download_select_os">
                                                        <a
                                                            href="https://update.cobot-i.com:5000/download/latest/osx"
                                                            className="cobot_download_select_os_link"
                                                        >
                                                            <img
                                                                src="images/ic_mac.png"
                                                                alt="Mac"
                                                            />
                                                            <span>Mac</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="tab_content video_guide">
                                        <div
                                            className="video_thumbnail_wrap install_thumbnail"
                                            onClick={() =>
                                                this.setVideoOpen("install")
                                            }
                                        >
                                            <img
                                                src="images/video_thumbnail-install.png"
                                                alt="설치 가이드 영상 썸네일"
                                            />
                                        </div>
                                        <div
                                            className="video_thumbnail_wrap"
                                            onClick={() =>
                                                this.setVideoOpen("question")
                                            }
                                        >
                                            <img
                                                src="images/video_thumbnail-question.png"
                                                alt="질문 가이드 영상 썸네일"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <div className="tab_container">
                                    <div className="tab_content">
                                        <div className="video_wrap">
                                            <video
                                                autoPlay="autoPlay"
                                                loop
                                                className="tab_video"
                                            >
                                                <source
                                                    type="video/mp4"
                                                    src="https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/cobot_landing_videos%2Flanding-4.mp4?alt=media&token=aae6109d-c5b9-4f3d-aa75-7e739d4ba34b"
                                                ></source>
                                            </video>
                                        </div>
                                        <div className="title_wrap">
                                            <p className="tab_title">
                                                차별화된 학습 진행
                                            </p>
                                            <p className="tab_sub_title">
                                                실시간으로 들어온 질문 파악이
                                                가능합니다,
                                                <br /> 온라인 학습 진행에 맞춰
                                                효율적으로 관리할 수 있어요.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab_content">
                                        <div className="video_wrap video_wrap_left_video">
                                            <video
                                                autoPlay="autoPlay"
                                                loop
                                                className="tab_video"
                                            >
                                                <source
                                                    type="video/mp4"
                                                    src="https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/cobot_landing_videos%2Flanding-5.mp4?alt=media&token=569aa5dc-da56-4e3d-bfba-275a1ea8723a"
                                                ></source>
                                            </video>
                                        </div>
                                        <div className="title_wrap">
                                            <p className="tab_title">
                                                다양한 답변 및 메시지 전송
                                            </p>
                                            <p className="tab_sub_title">
                                                카메라, 화면 녹화를 통해 빠르고
                                                표현력있게 전달해요.
                                                <br /> 파일 첨부, 공지사항,
                                                메시지 등으로 원활하게
                                                소통하세요.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab_content">
                                        <div className="video_wrap">
                                            <video
                                                autoPlay="autoPlay"
                                                loop
                                                className="tab_video"
                                            >
                                                <source
                                                    type="video/mp4"
                                                    src="https://firebasestorage.googleapis.com/v0/b/chocobot-36417.appspot.com/o/cobot_landing_videos%2Flanding-6.mp4?alt=media&token=8077c026-9dd6-4d7a-9afc-a6f982c7ab53"
                                                ></source>
                                            </video>
                                        </div>
                                        <div className="title_wrap">
                                            <p className="tab_title">
                                                그룹 개설 및 공동 관리
                                            </p>
                                            <p className="tab_sub_title">
                                                나만의 그룹을 만들어보세요.
                                                <br /> 다른 매니저를 초대하여
                                                함께 관리도 가능합니다.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="cobot_experience_wrap">
                                        <div className="cobot_experience_container cobot_experience_manager_container">
                                            <p className="cobot_experience_title cobot_experience_manager_title">
                                                온라인 학습의 놀라운 변화
                                                <br />
                                                코봇아이, 지금 경험해보세요!
                                            </p>
                                            <a
                                                className="cobot_experience_button cobot_experience_manager_button"
                                                href="https://answer.cobot-i.com/sign_up"
                                            >
                                                시작하기
                                            </a>
                                        </div>
                                    </div>
                                    <div className="tab_content video_guide teacher_guide_video">
                                        <div
                                            className="video_thumbnail_wrap"
                                            onClick={() =>
                                                this.setVideoOpen("answer")
                                            }
                                        >
                                            <img
                                                src="images/video_thumbnail-group.png"
                                                alt="답변 가이드 영상 썸네일"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
                {videoOpen && this.showVideoPopup()}
                {alertOpen && this.getExplainDownloadDialog()}
            </div>
        );
    }
}

class TabPanel extends Component {
    render() {
        return (
        <div hidden={this.props.value !== this.props.index}>
            <div>{this.props.children}</div>
        </div>
        );
    }
}

class VideoPopup extends Component {
    render() {
        return (
            <div className="guide_video_popup">
                <div className="guide_video_control_wrap">
                    <div className="guide_video_close_wrap">
                        <img className="close_vidoe_popup" onClick={this.props.close} src="images/ic_close.svg" alt="영상 닫기 버튼"></img>
                    </div>
                    <video controls autoPlay>
                        <source src={this.props.state.videoUrl} type="video/mp4"/>
                    </video>
                </div>
                <div className="guide_video_popup_shadow"></div>
            </div>
        );
    }
}
export default Explain;